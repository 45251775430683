<template>
  <Head>
    <title>Hello World</title>
    <meta name="description" content="desc" />
  </Head>
  <div className="relative">
    <Header />
    <main>
      <section
        class="relative flex items-center justify-center w-full h-full bg-none"
      >
        <img
          src="./../assets/bgimage.webp"
          alt="Triton waves background image"
          className="absolute -top-36 left-0 bottom-0 right-0 -z-10 h-full w-full"
        />
        <div className="relative flex-col pt-4 mb-72 lg:mb-96 text-center">
          <h1
            className="mx-auto max-w-3xl lg:max-w-4xl font-extrabold text-6xl lg:text-8xl mt-24 pb-4 text-white"
          >
            FAST. RELIABLE. SCALABLE.
          </h1>
          <h2
            className="mx-auto max-w-1xl font-medium text-xl  text-white mt-16 md:mt-6"
          >
            We provide hosted Solana RPC access.
          </h2>
          <Button text="Get started" dest="/pricing" />
        </div>
      </section>
      <section className="relative -mt-24">
        <div className="mx-8 md:mx-16"><Partners /></div>
        <div className="max-w-7xl mx-auto">
          <div
            className="hidden md:grid grid-cols-1 gap-8 mt-24 items-center md:mt-24 lg:mt-44 md:gap-24 lg:gap-36 mx-8 md:mx-16 lg:mx-28 md:grid-cols-2"
          >
            <div className="text-left">
              <h2>Provide a great user experience</h2>
              <p>
                The quality of your RPC server matters a great deal for the
                quality of your user experience. We give your users low-latency
                access with servers in the US, EU and Asia Pacific. We route
                your users' requests to the closest servers and ensure that
                those servers are healthy and provide correct answers.
              </p>
            </div>
            <div>
              <img
                src="./../assets/greatux.webp"
                alt="Provide great UX illustration"
                className=""
              />
            </div>
            <div>
              <img
                src="./../assets/Insights.webp"
                alt="Provide great UX illustration"
                className=""
              />
            </div>
            <div className="text-left">
              <h2>Get insights into your app</h2>
              <p>
                We provide detailed traffic and performance insights for your
                app through a custom dashboard where you can monitor your RPC
                usage and performance across both backend and frontend.
              </p>
            </div>
            <div className="text-left">
              <h2>Be launch ready</h2>
              <p>
                Whether it's for your launch, token sale or an IDO, let us know
                and we can scale your RPC on private servers to ensure that your
                event goes smoothly and without glitches. We run the largest
                Solana RPC deployment in the world and know how to cater to any
                size need.
              </p>
            </div>
            <div>
              <img
                src="./../assets/launching.webp"
                alt="Provide great UX illustration"
                className=""
              />
            </div>
            <div>
              <img
                src="./../assets/solanalocals.webp"
                alt="Provide great UX illustration"
                className=""
              />
            </div>
            <div className="text-left">
              <h2>We're Solana locals</h2>
              <p>
                We have been part of the Solana community from the early days,
                as validators and as app developers. We're always available on
                the Solana discord and on Telegram, so feel free to reach out.
              </p>
            </div>
          </div>

          <div className="flex flex-col md:hidden mx-8 md:mx-16 lg:mx-28">
            <div className="mb-12">
              <div>
                <img
                  src="./../assets/greatux.webp"
                  alt="Provide great UX illustration"
                  className="mb-4"
                />
              </div>
              <div className="text-left">
                <h2>Provide a great user experience</h2>
                <p>
                  The quality of your RPC server matters a great deal for the
                  quality of your user experience. We give your users
                  low-latency access with servers in the US, EU and Asia
                  Pacific. We route your users' requests to the closest servers
                  and ensure that those servers are healthy and provide correct
                  answers.
                </p>
              </div>
            </div>

            <div className="mb-12">
              <div>
                <img
                  src="./../assets/Insights.webp"
                  alt="Provide great UX illustration"
                  className="mb-4"
                />
              </div>
              <div className="text-left">
                <h2>Get insights into your app</h2>
                <p>
                  We provide detailed traffic and performance insights for your
                  app through a custom dashboard where you can monitor your RPC
                  usage and performance across both backend and frontend.
                </p>
              </div>
            </div>

            <div className="mb-12">
              <div>
                <img
                  src="./../assets/launching.webp"
                  alt="Provide great UX illustration"
                  className="mb-4"
                />
              </div>
              <div className="text-left">
                <h2>Be launch ready</h2>
                <p>
                  Whether it's for your launch, token sale or an IDO, let us
                  know and we can scale your RPC on private servers to ensure
                  that your event goes smoothly and without glitches. We run the
                  largest Solana RPC deployment in the world and know how to
                  cater to any size need.
                </p>
              </div>
            </div>

            <div className="mb-12">
              <div>
                <img
                  src="./../assets/solanalocals.webp"
                  alt="Provide great UX illustration"
                  className="mb-4"
                />
              </div>
              <div className="text-left">
                <h2>We're Solana locals</h2>
                <p>
                  We have been part of the Solana community from the early days,
                  as validators and as app developers. We're always available on
                  the Solana discord and on Telegram, so feel free to reach out.
                </p>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </section>
    </main>
  </div>
</template>

<script>
import { useHead } from "@vueuse/head";
import Button from "./../components/Button.vue";
import Partners from "./../components/Partners.vue";
import Footer from "./../components/Footer.vue";
import Header from "./../components/Header.vue";
export default {
  setup() {
    useHead({
      title: "Triton: Fast hosted Solana RPC access",
      meta: [
        {
          name: "description",
          content: "My beautiful website",
        },
      ],
    });
  },
  name: "Home",
  components: {
    Button,
    Partners,
    Footer,
    Header,
  },
};
</script>
