<template>
  <nav class="top-0 z-50 py-4 md:py-8" :class="headerStyling">
    <div
      class="container flex flex-col flex-wrap items-center justify-between px-4 mx-auto  md:px-0 md:flex-row"
      :class="headerStyling"
    >
      <div
        class="flex items-center justify-between w-full md:w-auto"
        :class="headerStyling"
      >
        <a
          href="/"
          class="text-xl font-bold text-indigo-600"
          :class="logotypenormal"
          ><InlineSvg
            :src="require('./../assets/logonew.svg')"
            class="h-8"
          /><span class="visually-hidden">Triton Logo</span></a
        >
        <a
          href="/"
          class="text-xl font-bold text-indigo-600"
          :class="logotypeblack"
          ><InlineSvg
            :src="require('./../assets/logonewblack.svg')"
            class="h-8"
          /><span class="visually-hidden">Triton Logo</span></a
        >
        <button
          class="rounded-lg md:hidden focus:outline-none focus:shadow-outline"
          v-on:click="toggleNavbar"
        >
          <span class="visually-hidden">Navbar Toggle</span>
          <div :class="menuButtonClass">
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              :class="{ 'hidden': showMenu, 'flex': !showMenu }"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="3"
                y="6.00195"
                width="30"
                height="4"
                rx="2"
                :fill="menuButtonClass"
              />
              <rect
                x="3"
                y="16.002"
                width="30"
                height="4"
                rx="2"
                :fill="menuButtonClass"
              />
              <rect
                x="11"
                y="26.002"
                width="22"
                height="4"
                rx="2"
                :fill="menuButtonClass"
              />
            </svg>
          </div>
          <div :class="menuButtonClass">
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              :class="{ 'hidden': !showMenu, 'flex': showMenu }"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="6"
                y="27"
                width="30"
                height="4"
                rx="2"
                transform="rotate(-45 6 27)"
                :fill="menuButtonClass"
              />
              <rect
                x="27.2129"
                y="29.8284"
                width="30"
                height="4"
                rx="2"
                transform="rotate(-135 27.2129 29.8284)"
                :fill="menuButtonClass"
              />
            </svg>
          </div>
        </button>
      </div>

      <div
        :class="{ 'hidden': !showMenu, 'flex': showMenu }"
        class="items-center md:flex md:flex-row"
      >
        <div
          class="flex flex-col items-center mx-auto mt-3 list-none  md:flex-row md:ml-auto md:mt-0"
        >
          <router-link to="/rpcpool" :class="headerClass">RPC Pool</router-link>
          <router-link to="/projects" :class="headerClass">Ecosystem</router-link>
          <router-link to="/professional-trading-centers" :class="headerClass">Trading Centers</router-link>
          <router-link to="/pricing" :class="headerClass">Pricing</router-link>
          <!--<router-link to="/blog" :class="headerClass">Blog</router-link>-->
          <router-link to="/about" :class="headerClass">About</router-link>
          <router-link to="/contact" :class="headerClass">Contact</router-link>
          <a
            href="https://metrics.rpcpool.com/login"
            rel="noopener noreferrer"
            target="_blank"
            :class="headerClass"
          >
            Sign in</a
          >
          <router-link to="/pricing">
            <button :class="ctaClass">Get Started</button>
          </router-link>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import InlineSvg from "vue-inline-svg";
import Button from "./Button.vue";
export default {
  name: "Header",
  data() {
    return {
      showMenu: false,
    };
  },
  methods: {
    toggleNavbar: function () {
      this.showMenu = !this.showMenu;
    },
  },
  computed: {
    headerClass() {
      return this.$route.path === "/" ? "menu-btn-lg" : "menu-btn-lg-pages";
    },
    ctaClass() {
      return this.$route.path === "/" ? "cta-btn" : "cta-btn-colored";
    },
    menuButtonClass() {
      return this.$route.path === "/" ? "white" : "black";
    },
    logotypeblack() {
      return this.$route.path === "/" ? "hidden" : "flex-item";
    },
    logotypenormal() {
      return this.$route.path === "/" ? "flex-item" : "hidden";
    },
    headerStyling() {
      return this.$route.path === "/"
        ? "bg-header-background-purple"
        : "bg-white";
    },
  },
  components: {
    InlineSvg,
    Button,
  },
};
</script>
