<template>
  <div className="mb-8 text-black font-semiboldn text-center">Trusted by</div>
  <div className="max-w-7xl mx-auto">
    <div class="hidden md:flex">
      <div
        className="flex flex-row gap-x-2 md:gap-x-8 h-0 md:h-8 mb-16 md:mb-0 justify-center items-center"
      >
        <a href="https://solana.com/" rel="noopener noreferrer"
          ><InlineSvg
            :src="require('./../assets/partners/Solana.svg')"
            width="300px"
            height="300px"
          /><span class="visually-hidden">Solana</span></a
        >
        <a href="https://www.orca.so/" rel="noopener noreferrer">
          <InlineSvg :src="require('./../assets/partners/Orca.svg')" /><span
            class="visually-hidden"
            >Orca</span
          ></a
        >
        <a href="https://step.finance/" rel="noopener noreferrer"
          ><InlineSvg :src="require('./../assets/partners/Step finance.svg')" />
          <span class="visually-hidden">Step Finance</span></a
        >
        <a href="https://bonfida.com/" rel="noopener noreferrer"
          ><InlineSvg :src="require('./../assets/partners/Bonfida.svg')" /><span
            class="visually-hidden"
            >Bonfida</span
          ></a
        >
        <a href="https://raydium.io/" rel="noopener noreferrer"
          ><InlineSvg :src="require('./../assets/partners/Raydium.svg')" /><span
            class="visually-hidden"
            >Raydium</span
          ></a
        >
        <a href="https://www.validators.app/" rel="noopener noreferrer"
          ><InlineSvg
            :src="require('./../assets/partners/Validators.svg')"
          /><span class="visually-hidden">Validators</span></a
        >
      </div>
    </div>
    <div class="flex md:hidden">
      <div className="flex flex-col gap-y-8 mb-24">
        <div
          className="flex flex-row gap-x-2 md:gap-x-8 h-0 md:h-8 mb-12 md:mb-0 justify-center items-center"
        >
          <a href="https://solana.com/" rel="noopener noreferrer"
            ><InlineSvg
              :src="require('./../assets/partners/Solana.svg')"
            /><span class="visually-hidden">Solana</span></a
          >
          <a href="https://www.orca.so/" rel="noopener noreferrer">
            <InlineSvg :src="require('./../assets/partners/Orca.svg')" /><span
              class="visually-hidden"
              >Orca</span
            ></a
          >
          <a href="https://step.finance/" rel="noopener noreferrer"
            ><InlineSvg
              :src="require('./../assets/partners/Step finance.svg')"
            />
            <span class="visually-hidden">Step Finance</span></a
          >
        </div>
        <div
          className="flex flex-row gap-x-2 md:gap-x-8 h-0 md:h-8 mb-16 md:mb-0 justify-center items-center"
        >
          <a href="https://bonfida.com/" rel="noopener noreferrer"
            ><InlineSvg
              :src="require('./../assets/partners/Bonfida.svg')"
            /><span class="visually-hidden">Bonfida</span></a
          >
          <a href="https://raydium.io/" rel="noopener noreferrer"
            ><InlineSvg
              :src="require('./../assets/partners/Raydium.svg')"
            /><span class="visually-hidden">Raydium</span></a
          >
          <a href="https://www.validators.app/" rel="noopener noreferrer"
            ><InlineSvg
              :src="require('./../assets/partners/Validators.svg')"
            /><span class="visually-hidden">Validators</span></a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";

export default {
  name: "Partners",
  // data() {
  //   return {
  //     showTwoRows: false,
  //   };
  // },
  // methods: {
  //   toggleTwoRows: function () {
  //     this.showTwoRows = !this.showTwoRows;
  //   },
  // },
  components: {
    InlineSvg,
  },
};
</script>
