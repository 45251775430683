<template>
  <footer class="mt-24 text-gray-600 bg-gray-50">
    <div class="container mx-auto">
      <div class="flex flex-col flex-wrap py-8 mx-auto md:flex-row">
        <div
          class="flex-shrink-0 w-64 mx-auto text-center md:mx-0 md:text-left"
        >
          <router-link
            to="/"
            class="flex items-center justify-center font-medium text-gray-900 appearance-none  title-font md:justify-start"
            ><InlineSvg
              :src="require('./../assets/logonewblack.svg')"
              class="h-8"
            /><span class="visually-hidden">Triton Logo</span>
          </router-link>

          <p class="mt-2 text-sm text-gray-500">
            Fast, reliable and scalable Solana RPC access
          </p>
        </div>
        <div class="flex flex-wrap flex-grow text-center md:pl-20 md:text-left">
          <div class="w-full px-4 lg:w-1/4 md:w-1/2">
            <nav class="flex flex-col md:mb-10">
              <router-link
                to="/rpcpool"
                class="py-1 text-sm text-gray-600  hover:text-gray-900 md:text-base"
                >
                RPC Pool
              </router-link>
              <router-link
                to="/professional-trading-centers"
                class="py-1 text-sm text-gray-600  hover:text-gray-900 md:text-base"
                >
                Trading Centers
              </router-link>
              <router-link
                to="/about"
                class="py-1 text-sm text-gray-600  hover:text-gray-900 md:text-base"
                >About</router-link
              >
              <router-link
                to="/pricing"
                class="py-1 text-sm text-gray-600  hover:text-gray-900 md:text-base"
                >Pricing</router-link
              >
              <!--<router-link
                to="/blog"
                class="py-1 text-sm text-gray-600  hover:text-gray-900 md:text-base"
                >Blog</router-link
              >-->
              <router-link
                to="/contact"
                class="py-1 text-sm text-gray-600  hover:text-gray-900 md:text-base"
                >Contact</router-link
              >
            </nav>
          </div>
        </div>
      </div>

      <div>
        <div
          class="flex flex-col flex-wrap justify-between py-4 mx-auto  sm:flex-row"
        >
          <p class="text-sm text-center text-gray-500 sm:text-left">
            © 2021 Triton by RPCPool
          </p>
          <span
            class="inline-flex justify-center mt-2  sm:ml-auto sm:mt-0 sm:justify-start"
          >
            <a
              class="ml-3 text-gray-500"
              href="https://t.me/joinchat/K0ONdq7fE4s0Mjdl"
              ref="noopener noreferrer"
            >
              <span class="visually-hidden">Telegram</span>
              <svg
                width="32px"
                height="32px"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                color="#505050"
              >
                <title>Telegram icon</title>
                <path
                  d="M23.91 3.79L20.3 20.84c-.25 1.21-.98 1.5-2 .94l-5.5-4.07-2.66 2.57c-.3.3-.55.56-1.1.56-.72 0-.6-.27-.84-.95L6.3 13.7l-5.45-1.7c-1.18-.35-1.19-1.16.26-1.75l21.26-8.2c.97-.43 1.9.24 1.53 1.73z"
                ></path>
              </svg>
            </a>

            <a
              class="ml-3 text-gray-500"
              href="https://discord.gg/rbgvBum63B"
              ref="noopener noreferrer"
            >
              <span class="visually-hidden">Discord</span>
              <svg
                width="32px"
                height="32px"
                role="img"
                viewBox="0 0 24 24"
                fill="currentColor"
                color="#505050"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Discord icon</title>
                <path
                  d="M20.222 0c1.406 0 2.54 1.137 2.607 2.475V24l-2.677-2.273-1.47-1.338-1.604-1.398.67 2.205H3.71c-1.402 0-2.54-1.065-2.54-2.476V2.48C1.17 1.142 2.31.003 3.715.003h16.5L20.222 0zm-6.118 5.683h-.03l-.202.2c2.073.6 3.076 1.537 3.076 1.537-1.336-.668-2.54-1.002-3.744-1.137-.87-.135-1.74-.064-2.475 0h-.2c-.47 0-1.47.2-2.81.735-.467.203-.735.336-.735.336s1.002-1.002 3.21-1.537l-.135-.135s-1.672-.064-3.477 1.27c0 0-1.805 3.144-1.805 7.02 0 0 1 1.74 3.743 1.806 0 0 .4-.533.805-1.002-1.54-.468-2.14-1.404-2.14-1.404s.134.066.335.2h.06c.03 0 .044.015.06.03v.006c.016.016.03.03.06.03.33.136.66.27.93.4.466.202 1.065.403 1.8.536.93.135 1.996.2 3.21 0 .6-.135 1.2-.267 1.8-.535.39-.2.87-.4 1.397-.737 0 0-.6.936-2.205 1.404.33.466.795 1 .795 1 2.744-.06 3.81-1.8 3.87-1.726 0-3.87-1.815-7.02-1.815-7.02-1.635-1.214-3.165-1.26-3.435-1.26l.056-.02zm.168 4.413c.703 0 1.27.6 1.27 1.335 0 .74-.57 1.34-1.27 1.34-.7 0-1.27-.6-1.27-1.334.002-.74.573-1.338 1.27-1.338zm-4.543 0c.7 0 1.266.6 1.266 1.335 0 .74-.57 1.34-1.27 1.34-.7 0-1.27-.6-1.27-1.334 0-.74.57-1.338 1.27-1.338z"
                ></path>
              </svg>
            </a>
          </span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import InlineSvg from "vue-inline-svg";
export default {
  name: "Footer",
  components: {
    InlineSvg,
  },
};
</script>
