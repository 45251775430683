import { createApp } from "vue";
import App from "./App.vue";
import "prismjs/themes/prism.css";
import "./registerServiceWorker";
import router from "./router";
import { createHead } from "@vueuse/head";
import Default from "./layouts/default.vue";
import Page from "./layouts/page.vue";
import store from "./store";
import "@/assets/css/tailwind.css"; //@ points to /src in vue
import mixinTitle from "./mixins/title";

//Vue.mixin(mixinTitle);
const app = createApp(App);
const head = createHead();

app.component("page", Page);
app.component("default", Default);

app.use(head).use(store).use(router).mount("#app");
