<template>
  <div class="flex flex-col justify-between h-screen">
    <div>
      <Header />
      <div className="container mx-auto px-4">
        <div className="">
          <slot />
        </div>
      </div>
    </div>
    <div className="bottom-0"><Footer /></div>
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";

export default {
  components: {
    Footer,
    Header,
  },
};
</script>
