<template>
  <router-link tag="button" :to="dest"
    className="px-8 py-2 font-semibold tracking-tight bg-white rounded-xl text-global-purple"
  >
    {{ text }}
  </router-link>
</template>

<script>
export default {
  name: "Button",
  props: {
    text: String,
    dest: String,
  },
};
</script>
